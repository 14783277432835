export interface User {
   id: number;
   username: string;
   name: string;
   headline: string;
   mobile: string | null;
   email: string | null;
   email_list: string[] | any;
   birth_date: string | null;
   bio: string | null;
   photo: string | null;
   cover: string | null;
   gender: string | null;
   country: string | null;
   city: string | null;
   external_link: string[] | any;
   role: any[];
   access_token: string;
   refresh_token: string;
   assignment: Assignment[];
   date_joined: string;
   referralCode: string;
}

export interface Group {
   id: number;
   name: string;
}

export interface Assignment {
   id: number;
   type_id: number;
   user_id: number;
   owner_id: number;
   owner_username: string;
}

export enum Roles {
   ADMIN = 'admin',
   TEACHER = 'teacher',
   EDITOR = 'Localadmin',
   GREY_PUBLISHER = 'grey_publisher',
   BUSINESS = 'business'
}

export enum AssistantTypes {
   SOCIAL_ASSISTANT = 34,
   QUIZ_ASSISTANT = 1
}
